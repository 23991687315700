import { Injectable } from '@angular/core';
import { HEROES_DATA } from '../app.common.util/heroes';
import { Heroe } from '../app.common.util/app.heroe.interface';

@Injectable()
export class HeroeService {
  constructor() {
    console.log('Service is ready for use');
  }

  getHeroes(): Heroe[] {
    const heroArr: Heroe[] = [];
    let idx: number;
    idx = 0;
    HEROES_DATA.forEach(function(hero) {
      hero.idx = idx;
      heroArr.push(hero);
      idx++;
    });
    return heroArr;
  }

  getHeroeById(id: string) {
    return this.getHeroes()[id];
  }

  getHeroByCoincidence(value: string): Heroe[] {
    const valueToSearch = value.toLowerCase();

    return this.getHeroes().filter(function(hero) {
      return hero.name.toLowerCase().indexOf(valueToSearch) >= 0;
    });
  }
}
