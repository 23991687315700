import { Component, OnInit } from '@angular/core';
import { HeroeService } from '../../services/heroes.service';
import { ActivatedRoute } from '@angular/router';
import { Heroe } from '../../app.common.util/app.heroe.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html'
})
export class SearchResultComponent implements OnInit {
  heroeResult: Heroe[] = [];
  searchCriteria: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _heroService: HeroeService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.searchCriteria = params['searchParam'];
      this.heroeResult = this._heroService.getHeroByCoincidence(
        params['searchParam']
      );
    });
  }

  goToHeroeDetail(id: number) {
    this._router.navigate(['/heroedetail', id]);
  }
}
