import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Heroe } from '../../app.common.util/app.heroe.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hero-card',
  templateUrl: './hero-card.component.html',
  styles: []
})
export class HeroCardComponent implements OnInit {
  @Input() hero: Heroe = new Heroe();
  @Input() idx: number;

  @Output() selectedHero: EventEmitter<number>;

  constructor(private _router: Router) {
    this.selectedHero = new EventEmitter();
  }

  ngOnInit() {}

  goToHeroeDetail() {
    this._router.navigate(['/heroedetail', this.idx]);
  }
}
