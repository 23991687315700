import { Component, OnInit } from '@angular/core';
import { HeroeService } from '../../services/heroes.service';
import { Heroe } from '../../app.common.util/app.heroe.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html'
})
export class HeroesComponent implements OnInit {
  heroes: Heroe[] = [];

  constructor(private _heroeService: HeroeService, private _router: Router) {}

  ngOnInit() {
    this.heroes = this._heroeService.getHeroes();
  }

  goToHeroeDetail(id: number) {
    this._router.navigate(['/heroedetail', id]);
  }
}
