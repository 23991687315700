import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { HeroesComponent } from './components/heroes/heroes.component';
import { HeroedetailComponent } from './components/heroedetail/heroedetail.component';
import { SearchResultComponent } from './components/search-result/search-result.component';

const APP_ROUTES = [
  { path: 'home', component: HomeComponent },
  { path: 'heroes', component: HeroesComponent },
  { path: 'about', component: AboutComponent },
  { path: 'heroedetail/:id', component: HeroedetailComponent },
  { path: 'searchresult/:searchParam', component: SearchResultComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
