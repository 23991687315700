import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Heroe } from '../../app.common.util/app.heroe.interface';
import { HeroeService } from '../../services/heroes.service';

@Component({
  selector: 'app-heroedetail',
  templateUrl: './heroedetail.component.html'
})
export class HeroedetailComponent {
  heroe: Heroe = new Heroe();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _heroService: HeroeService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.heroe = this._heroService.getHeroeById(params['id']);
    });
  }
}
