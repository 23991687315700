import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Routes
import { APP_ROUTING } from './app.routes';

// Services
import { HeroeService } from './services/heroes.service';
// Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { HeroesComponent } from './components/heroes/heroes.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeroedetailComponent } from './components/heroedetail/heroedetail.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { HeroCardComponent } from './components/hero-card/hero-card.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    HeroesComponent,
    FooterComponent,
    HeroedetailComponent,
    SearchResultComponent,
    HeroCardComponent
  ],
  imports: [BrowserModule, APP_ROUTING],
  providers: [HeroeService],
  bootstrap: [AppComponent]
})
export class AppModule {}
